import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

import { PRIVILEGE_CREATE_ADJUSTMENTS, PRIVILEGE_READ_ADJUSTMENTS, PRIVILEGE_UPDATE_ADJUSTMENTS,
    PRIVILEGE_READ_LISTS,
    PRIVILEGE_READ_CUSTOMERS,
    PRIVILEGE_READ_PRODUCTS,
    PRIVILEGE_CREATE_PURCHASES, PRIVILEGE_READ_PURCHASES, PRIVILEGE_UPDATE_PURCHASES,
    PRIVILEGE_CREATE_SALES, PRIVILEGE_READ_SALES, PRIVILEGE_UPDATE_SALES,
    PRIVILEGE_READ_SUPPLIERS,
    PRIVILEGE_CREATE_USERS, PRIVILEGE_READ_USERS, PRIVILEGE_UPDATE_USERS,
    PRIVILEGE_READ_REPORTS,
    PRIVILEGE_READ_CONFIGS, } from '../constants/constants';

//Hacky way to get around slow load time on Products and Adjustments page.
//We load XLSX and ReactExport during initial load so the browser cache them.
//It works even after the user quit the browser (doesn't work on Incognito).
//I haven't figure out why does React import these two packages so slowly.
import XLSX from 'xlsx';
import ReactExport from "react-data-export";

/* lazy load all the views */

/*
    Custom Pages
*/
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
// const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// Dashboard
const Dashboard = React.lazy(() => import('../customPages/dashboard/index'));

// Reports
const SalesReport = React.lazy(() => import('../customPages/reports/SalesReport'));
const PaymentAccountsReport = React.lazy(() => import('../customPages/reports/PaymentAccountsReport'));
const ProductsReport = React.lazy(() => import('../customPages/reports/ProductsReport'));
const CustomersReport = React.lazy(() => import('../customPages/reports/CustomersReport'));
const SuppliersReport = React.lazy(() => import('../customPages/reports/SuppliersReport'));
const PlatformsReport = React.lazy(() => import('../customPages/reports/PlatformsReport'));
const ShippingMethodsReport = React.lazy(() => import('../customPages/reports/ShippingMethodsReport'));
// const PaymentAccountsReport = React.lazy(() => import('../customPages/reports/PaymentAccountsReport'));

// Sales
const Sales = React.lazy(() => import('../customPages/transaction/Sales'));
const CreateSale = React.lazy(() => import('../customPages/transaction/CreateSale'));
const ReadSale = React.lazy(() => import('../customPages/transaction/ReadSale'));
const UpdateSale = React.lazy(() => import('../customPages/transaction/UpdateSale'));

// Purchases
const Purchases = React.lazy(() => import('../customPages/transaction/Purchases'));
const CreatePurchase = React.lazy(() => import('../customPages/transaction/CreatePurchase'));
const ReadPurchase = React.lazy(() => import('../customPages/transaction/ReadPurchase'));
const UpdatePurchase = React.lazy(() => import('../customPages/transaction/UpdatePurchase'));

// Products
const Products = React.lazy(() => import('../customPages/product/Products'));
const ReadProduct = React.lazy(() => import('../customPages/product/ReadProduct'));

// Adjustments
const Adjustments = React.lazy(() => import('../customPages/adjustment/Adjustments'));
const CreateAdjustment = React.lazy(() => import('../customPages/adjustment/CreateAdjustment'));
const ReadAdjustment = React.lazy(() => import('../customPages/adjustment/ReadAdjustment'));
const UpdateAdjustment = React.lazy(() => import('../customPages/adjustment/UpdateAdjustment'));

// Contacts
const Customers = React.lazy(() => import('../customPages/contact/Customers'));
const Suppliers = React.lazy(() => import('../customPages/contact/Suppliers'));

// Lists
const Categories = React.lazy(() => import('../customPages/list/Categories'));
const Units = React.lazy(() => import('../customPages/list/Units'));
const Platforms = React.lazy(() => import('../customPages/list/Platforms'));
const ShippingMethods = React.lazy(() => import('../customPages/list/ShippingMethods'));

// Users
const Users = React.lazy(() => import('../customPages/user/Users'));
const CreateUser = React.lazy(() => import('../customPages/user/CreateUser'));
const UpdateUser = React.lazy(() => import('../customPages/user/UpdateUser'));

// Settings
const CompanySettings = React.lazy(() => import('../customPages/setting/CompanySettings'));
const SaleSettings = React.lazy(() => import('../customPages/setting/SaleSettings'));
const PurchaseSettings = React.lazy(() => import('../customPages/setting/PurchaseSettings'));
const ProductSettings = React.lazy(() => import('../customPages/setting/ProductSettings'));
const MembershipSettings = React.lazy(() => import('../customPages/setting/MembershipSettings'));
// const InvoiceTemplateSettings = React.lazy(() => import('../customPages/setting/InvoiceTemplateSettings'));




// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                // return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                return <Redirect to={{ pathname: '/account/logout', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();

            // Check if there is an overlap between the privileges in roles (frontend) & the privileges returned by the backend. Returns a boolean.
            const isOverlap = roles ? roles.some(item => loggedInUser.privileges.includes(item)) : false;

            // check if route is restricted by role
            if (roles && !isOverlap) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// const PrivateRoute = ({ component: Component, roles, ...rest }) => (
//     <Route
//         {...rest}
//         render={props => {
//             if (!isUserAuthenticated()) {
//                 // not logged in so redirect to login page with the return url
//                 // return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
//                 return <Redirect to={{ pathname: '/account/logout', state: { from: props.location } }} />;
//             }

//             const loggedInUser = getLoggedInUser();
//             // check if route is restricted by role
//             if (roles && roles.indexOf(loggedInUser.role) === -1) {
//                 // role not authorised so redirect to home page
//                 return <Redirect to={{ pathname: '/' }} />;
//             }

//             // authorised so return component
//             return <Component {...props} />;
//         }}
//     />
// );

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        // {
        //     path: '/account/confirm',
        //     name: 'Confirm',
        //     component: Confirm,
        //     route: Route,
        // },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// const dashboard = {
//     path: '/dashboard',
//     name: 'Dashboard',
//     component: Dashboard,
//     route: PrivateRoute,
//     icon: 'mdi mdi-speedometer-slow',
// }

const dashboard = {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    route: PrivateRoute,
    icon: 'mdi mdi-speedometer-slow',
}

const reportRoutes = {
    path: '/reports',
    name: 'Laporan',
    route: PrivateRoute,
    icon: 'uil-chart-line',
    children: [
        {
            path: '/reports/sales',
            name: 'Laba Rugi',
            component: SalesReport,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_REPORTS],
        },
        {
            path: '/reports/payment-accounts',
            name: 'Akun Pembayaran',
            component: PaymentAccountsReport,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_REPORTS],
        },
        {
            path: '/reports/products',
            name: 'Produk',
            component: ProductsReport,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_REPORTS],
        },
        {
            path: '/reports/customers',
            name: 'Customer',
            component: CustomersReport,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_REPORTS],
        },
        {
            path: '/reports/suppliers',
            name: 'Supplier',
            component: SuppliersReport,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_REPORTS],
        },
        {
            path: '/reports/platforms',
            name: 'Platform',
            component: PlatformsReport,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_REPORTS],
        },
        {
            path: '/reports/shipping-methods',
            name: 'Pengiriman',
            component: ShippingMethodsReport,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_REPORTS],
        },
        // {
        //     path: '/reports/payment-accounts',
        //     name: 'Akun',
        //     component: PaymentAccountsReport,
        //     route: PrivateRoute,
        //     roles: [PRIVILEGE_READ_REPORTS],
        // },
    ],
};

// const saleRoutes = {
//     path: '/sales',
//     name: 'Penjualan',
//     component: Sales,
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'dripicons-cart',
//     exact: true,
// }

// const addSaleRoutes = {
//     path: '/sales/create',
//     component: AddSale,
//     route: PrivateRoute,
//     roles: ['Admin'],
//     exact: true,
//     hidden: true,
// }

const saleRoutes = {
    path: '/sales',
    name: 'Penjualan',
    component: Sales,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_SALES],
    icon: 'dripicons-cart',
    exact: true,
}

const createSaleRoutes = {
    path: '/sales/create',
    component: CreateSale,
    route: PrivateRoute,
    roles: [PRIVILEGE_CREATE_SALES],
    exact: true,
    hidden: true,
}

const readSaleRoutes = {
    path: '/sales/read/:id',
    component: ReadSale,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_SALES],
    exact: true,
    hidden: true,
}

const updateSaleRoutes = {
    path: '/sales/update/:id',
    component: UpdateSale,
    route: PrivateRoute,
    roles: [PRIVILEGE_UPDATE_SALES],
    exact: true,
    hidden: true,
}

const purchaseRoutes = {
    path: '/purchases',
    name: 'Pembelian',
    component: Purchases,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_PURCHASES],
    icon: 'mdi mdi-credit-card-outline',
    exact: true,
}

const createPurchaseRoutes = {
    path: '/purchases/create',
    component: CreatePurchase,
    route: PrivateRoute,
    roles: [PRIVILEGE_CREATE_PURCHASES],
    exact: true,
    hidden: true,
}

const readPurchaseRoutes = {
    path: '/purchases/read/:id',
    component: ReadPurchase,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_PURCHASES],
    exact: true,
    hidden: true,
}

const updatePurchaseRoutes = {
    path: '/purchases/update/:id',
    component: UpdatePurchase,
    route: PrivateRoute,
    roles: [PRIVILEGE_UPDATE_PURCHASES],
    exact: true,
    hidden: true,
}

const productRoutes = {
    path: '/products',
    name: 'Produk',
    component: Products,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_PRODUCTS],
    icon: 'mdi mdi-barcode-scan',
    exact: true,
}

const readProductRoutes = {
    path: '/products/read/:id',
    component: ReadProduct,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_PRODUCTS],
    exact: true,
    hidden: true,
}

const adjustmentRoutes = {
    path: '/adjustments',
    name: 'Stok Opname',
    component: Adjustments,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_ADJUSTMENTS],
    icon: 'mdi mdi-warehouse',
    exact: true,
}

const createAdjustmentRoutes = {
    path: '/adjustments/create',
    component: CreateAdjustment,
    route: PrivateRoute,
    roles: [PRIVILEGE_CREATE_ADJUSTMENTS],
    exact: true,
    hidden: true,
}

const readAdjustmentRoutes = {
    path: '/adjustments/read/:id',
    component: ReadAdjustment,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_ADJUSTMENTS],
    exact: true,
    hidden: true,
}

const updateAdjustmentRoutes = {
    path: '/adjustments/update/:id',
    component: UpdateAdjustment,
    route: PrivateRoute,
    roles: [PRIVILEGE_UPDATE_ADJUSTMENTS],
    exact: true,
    hidden: true,
}

const customerRoutes = {
    path: '/customers',
    name: 'Customer',
    component: Customers,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_CUSTOMERS],
    icon: 'dripicons-user-id',
}

const supplierRoutes = {
    path: '/suppliers',
    name: 'Supplier',
    component: Suppliers,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_SUPPLIERS],
    icon: 'mdi mdi-truck',
}

const listRoutes = {
    path: '/lists',
    name: 'List',
    route: PrivateRoute,
    icon: 'mdi mdi-format-list-bulleted-square',
    children: [
        {
            path: '/lists/categories',
            name: 'Kategori',
            component: Categories,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_LISTS],
        },
        {
            path: '/lists/units',
            name: 'Satuan',
            component: Units,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_LISTS],
        },
        {
            path: '/lists/platforms',
            name: 'Platform',
            component: Platforms,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_LISTS],
        },
        {
            path: '/lists/shipping-methods',
            name: 'Metode Pengiriman',
            component: ShippingMethods,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_LISTS],
        },
    ]
}

const userRoutes = {
    path: '/users',
    name: 'Users',
    component: Users,
    route: PrivateRoute,
    roles: [PRIVILEGE_READ_USERS],
    icon: 'mdi mdi-account-group',
    exact: true,
}

const createUserRoutes = {
    path: '/users/create',
    component: CreateUser,
    route: PrivateRoute,
    roles: [PRIVILEGE_CREATE_USERS],
    exact: true,
    hidden: true,
}

const updateUserRoutes = {
    path: '/users/update/:uid',
    component: UpdateUser,
    route: PrivateRoute,
    roles: [PRIVILEGE_UPDATE_USERS],
    exact: true,
    hidden: true,
}

const settingRoutes = {
    path: '/settings',
    name: 'Pengaturan',
    route: PrivateRoute,
    icon: 'dripicons-gear',
    children: [
        {
            path: '/settings/company',
            name: 'Perusahaan',
            component: CompanySettings,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_CONFIGS],
        },
        {
            path: '/settings/sale',
            name: 'Penjualan',
            component: SaleSettings,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_CONFIGS],
        },
        {
            path: '/settings/purchase',
            name: 'Pembelian',
            component: PurchaseSettings,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_CONFIGS],
        },
        // {
        //     path: '/settings/product',
        //     name: 'Product',
        //     component: ProductSettings,
        //     route: PrivateRoute,
        //     roles: [PRIVILEGE_READ_CONFIGS],
        // },
        {
            path: '/settings/membership',
            name: 'Membership',
            component: MembershipSettings,
            route: PrivateRoute,
            roles: [PRIVILEGE_READ_CONFIGS],
        },
        // {
        //     path: '/settings/invoice-template',
        //     name: 'Template Faktur',
        //     component: InvoiceTemplateSettings,
        //     route: PrivateRoute,
        //     roles: [PRIVILEGE_READ_CONFIGS],
        // },
    ]
}






const appRoutes = [
    dashboard,
    reportRoutes,
    saleRoutes,
    createSaleRoutes,
    readSaleRoutes,
    updateSaleRoutes,
    purchaseRoutes,
    createPurchaseRoutes,
    readPurchaseRoutes,
    updatePurchaseRoutes,
    productRoutes,
    readProductRoutes,
    adjustmentRoutes,
    createAdjustmentRoutes,
    readAdjustmentRoutes,
    updateAdjustmentRoutes,
    customerRoutes,
    supplierRoutes,
    listRoutes,
    settingRoutes,
    userRoutes,
    createUserRoutes,
    updateUserRoutes,
];

// const filterRoutesByPrivileges = () => {
//     let results = [];
//     const privilegesFromBackend = getLoggedInUser() ? getLoggedInUser().privileges : [];
//     // const privilegesFromBackend = getLoggedInUser().privilege;
//     results.push(dashboard);
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_REPORTS)) {
//         results.push(reportRoutes);
//     }
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_SALES)) {
//         results.push(saleRoutes);
//         results.push(createSaleRoutes);
//         results.push(readSaleRoutes);
//         results.push(updateSaleRoutes);
//     }
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_PURCHASES)) {
//         results.push(purchaseRoutes);
//         results.push(createPurchaseRoutes);
//         results.push(readPurchaseRoutes);
//         results.push(updatePurchaseRoutes);
//     }
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_PRODUCTS)) {
//         results.push(productRoutes);
//         results.push(readProductRoutes);
//     }
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_ADJUSTMENTS)) {
//         results.push(adjustmentRoutes);
//         results.push(createAdjustmentRoutes);
//         results.push(readAdjustmentRoutes);
//         results.push(updateAdjustmentRoutes);
//     }
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_CUSTOMERS)) {
//         results.push(customerRoutes);
//     }
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_SUPPLIERS)) {
//         results.push(supplierRoutes);
//     }
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_LISTS)) {
//         results.push(listRoutes);
//     }
//     if (privilegesFromBackend.includes(PRIVILEGE_READ_USERS)) {
//         results.push(userRoutes);
//         results.push(createUserRoutes);
//         results.push(updateUserRoutes);
//     }
//     return results;
// }

// const appRoutes = filterRoutesByPrivileges();

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
// const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes, pageRoutes, uiRoutes];
const allRoutes = [rootRoute, ...appRoutes, authRoutes];

// const authProtectedRoutes = [dashboardRoutes, ...appRoutes, pageRoutes, uiRoutes];
const authProtectedRoutes = [rootRoute, ...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };