export const MARKETING_WEBSITE_URL = 'https://urustoko.com';

export const LOGO_HEIGHT = 32;
export const LOGO_IMG_ALT = "UrusToko Logo";

export const PAGINATION_COUNT_PER_PAGE = 20;
export const USERS_PAGINATION_COUNT_PER_PAGE = 5;
export const PAGINATION_SIZE = 9;

export const PRODUCT_ASYNC_PAGINATE_LIMIT = 10;
export const CATEGORY_ASYNC_PAGINATE_LIMIT = 10;
export const UNIT_ASYNC_PAGINATE_LIMIT = 10;
export const SUPPLIER_ASYNC_PAGINATE_LIMIT = 10;
export const CUSTOMER_ASYNC_PAGINATE_LIMIT = 10;
export const PLATFORM_ASYNC_PAGINATE_LIMIT = 10;
export const PAYMENT_METHOD_ASYNC_PAGINATE_LIMIT = 10;
export const SHIPPING_METHOD_ASYNC_PAGINATE_LIMIT = 10;

export const ADJUSTMENT_ROW_INITIAL_COUNT = 6;

export const ALERT_MILLISECONDS = 5000;

export const EXPIRY_DATE_REMINDER_DAYS_RANGE = 14;

//The string literals have to be exactly the same as the ones on the backend
export const PAYMENT_STATUS_UNPAID = 'Unpaid';
export const PAYMENT_STATUS_PAID = 'Paid';
export const PAYMENT_STATUS_OVERDUE = 'Overdue';

export const SHIPMENT_STATUS_PACKED = 'Dikemas';
export const SHIPMENT_STATUS_SHIPPED = 'Dikirim';

export const CUSTOMERS_REPORT_DEFAULT_SORT_FIELD = 'name';
export const CUSTOMERS_REPORT_DEFAULT_SORT_ORDER = 'asc';
export const PAYMENT_ACCOUNTS_REPORT_DEFAULT_SORT_FIELD = 'name';
export const PAYMENT_ACCOUNTS_REPORT_DEFAULT_SORT_ORDER = 'asc';

// TRUE_STRINGS is used on Excel file to represent a boolean value (true)
export const TRUE_STRINGS = ['y', 'Y'];

export const NAME_COLUMN_ON_DB = 'name';
export const NAME_COLUMN_ON_EXCEL = 'Nama*';
export const DESCRIPTION_COLUMN_ON_DB = 'description';
export const DESCRIPTION_COLUMN_ON_EXCEL = 'Deskripsi';
export const CATEGORY_COLUMN_ON_DB = 'categoryName';
export const CATEGORY_COLUMN_ON_EXCEL = 'Kategori';
export const UNIT_COLUMN_ON_DB = 'unitName';
export const UNIT_COLUMN_ON_EXCEL = 'Satuan';
export const IS_ALLOW_SALE_COLUMN_ON_DB = 'isAllowSale';
export const IS_ALLOW_SALE_COLUMN_ON_EXCEL = 'AktifkanUntukDijual';
export const SELLING_PRICE_COLUMN_ON_DB = 'sellingPrice';
export const SELLING_PRICE_COLUMN_ON_EXCEL = 'HargaJual';
export const IS_ALLOW_PURCHASE_COLUMN_ON_DB = 'isAllowPurchase';
export const IS_ALLOW_PURCHASE_COLUMN_ON_EXCEL = 'AktifkanUntukDibeli';
export const PURCHASE_COST_COLUMN_ON_DB = 'purchaseCost';
export const PURCHASE_COST_COLUMN_ON_EXCEL = 'HargaBeli';
export const IS_TRACK_INVENTORY_COLUMN_ON_DB = 'isTrackInventory';
export const IS_TRACK_INVENTORY_COLUMN_ON_EXCEL = 'MonitorStokProduk';
export const STARTING_INVENTORY_COLUMN_ON_DB = 'startingInventory';
export const STARTING_INVENTORY_COLUMN_ON_EXCEL = 'StokAwal';
export const MINIMUM_INVENTORY_LEVEL_COLUMN_ON_DB = 'minimumInventoryLevel';
export const MINIMUM_INVENTORY_LEVEL_COLUMN_ON_EXCEL = 'BatasStokMinimum';
export const PRODUCT_COST_COLUMN_ON_DB = 'productCost';
export const PRODUCT_COST_COLUMN_ON_EXCEL = 'HargaModal';

export const CURRENT_INVENTORY_COLUMN_ON_DB = 'currentInventory';
export const CURRENT_INVENTORY_COLUMN_ON_EXCEL = 'KuantitasSistem';
export const NEW_QUANTITY_COLUMN_ON_DB = 'newQuantity';
export const NEW_QUANTITY_COLUMN_ON_EXCEL = 'KuantitasBaru';

export const ALLOW_PURCHASE_STRING = 'purchase';
export const ALLOW_SALE_STRING = 'sale';

export const CRUD_CREATE = 'crudCreate';
export const CRUD_READ = 'crudRead';
export const CRUD_UPDATE = 'crudUpdate';

export const PRIVILEGE_CREATE_ADJUSTMENTS = 'create:adjustments';
export const PRIVILEGE_READ_ADJUSTMENTS = 'read:adjustments';
export const PRIVILEGE_UPDATE_ADJUSTMENTS = 'update:adjustments';
export const PRIVILEGE_DELETE_ADJUSTMENTS = 'delete:adjustments';
export const PRIVILEGE_CREATE_LISTS = 'create:lists'; // Examples of LISTS: category, unit, platform, shipping method, payment accounts
export const PRIVILEGE_READ_LISTS = 'read:lists';
export const PRIVILEGE_UPDATE_LISTS = 'update:lists';
export const PRIVILEGE_DELETE_LISTS = 'delete:lists';
export const PRIVILEGE_READ_CONFIGS = 'read:configs';
export const PRIVILEGE_UPDATE_CONFIGS = 'update:configs';
export const PRIVILEGE_CREATE_CUSTOMERS = 'create:customers';
export const PRIVILEGE_READ_CUSTOMERS = 'read:customers';
export const PRIVILEGE_UPDATE_CUSTOMERS = 'update:customers';
export const PRIVILEGE_DELETE_CUSTOMERS = 'delete:customers';
export const PRIVILEGE_CREATE_PRODUCTS = 'create:products';
export const PRIVILEGE_READ_PRODUCTS = 'read:products';
export const PRIVILEGE_UPDATE_PRODUCTS = 'update:products';
export const PRIVILEGE_DELETE_PRODUCTS = 'delete:products';
export const PRIVILEGE_CREATE_PURCHASE_PAYMENTS = 'create:purchase_payments';
export const PRIVILEGE_READ_PURCHASE_PAYMENTS = 'read:purchase_payments';
export const PRIVILEGE_UPDATE_PURCHASE_PAYMENTS = 'update:purchase_payments';
export const PRIVILEGE_DELETE_PURCHASE_PAYMENTS = 'delete:purchase_payments';
export const PRIVILEGE_CREATE_PURCHASES = 'create:purchases';
export const PRIVILEGE_READ_PURCHASES = 'read:purchases';
export const PRIVILEGE_UPDATE_PURCHASES = 'update:purchases';
export const PRIVILEGE_DELETE_PURCHASES = 'delete:purchases';
export const PRIVILEGE_CREATE_SALE_PAYMENTS = 'create:sale_payments';
export const PRIVILEGE_READ_SALE_PAYMENTS = 'read:sale_payments';
export const PRIVILEGE_UPDATE_SALE_PAYMENTS = 'update:sale_payments';
export const PRIVILEGE_DELETE_SALE_PAYMENTS = 'delete:sale_payments';
export const PRIVILEGE_CREATE_SALE_SHIPMENTS = 'create:sale_shipments';
export const PRIVILEGE_READ_SALE_SHIPMENTS = 'read:sale_shipments';
export const PRIVILEGE_UPDATE_SALE_SHIPMENTS = 'update:sale_shipments';
export const PRIVILEGE_DELETE_SALE_SHIPMENTS = 'delete:sale_shipments';
export const PRIVILEGE_CREATE_SALES = 'create:sales';
export const PRIVILEGE_READ_SALES = 'read:sales';
export const PRIVILEGE_UPDATE_SALES = 'update:sales';
export const PRIVILEGE_DELETE_SALES = 'delete:sales';
export const PRIVILEGE_CREATE_SUPPLIERS = 'create:suppliers';
export const PRIVILEGE_READ_SUPPLIERS = 'read:suppliers';
export const PRIVILEGE_UPDATE_SUPPLIERS = 'update:suppliers';
export const PRIVILEGE_DELETE_SUPPLIERS = 'delete:suppliers';
export const PRIVILEGE_CREATE_USERS = 'create:users';
export const PRIVILEGE_READ_USERS = 'read:users';
export const PRIVILEGE_UPDATE_USERS = 'update:users';
export const PRIVILEGE_DELETE_USERS = 'delete:users';
export const PRIVILEGE_READ_REPORTS = 'read:reports'; // Reports can only be read

export const INVENTORY_FILTER_ALL = 'all';
export const INVENTORY_FILTER_READY = 'ready';
export const INVENTORY_FILTER_LOW = 'low';
export const INVENTORY_FILTER_EMPTY = 'empty';