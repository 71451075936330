import { getLoggedInUser } from '../helpers/authUtils';
import axios from '../axios/axios-instance';
import { Cookies } from 'react-cookie';

// This function accepts an array of objects, returns the index of a particular object in that array.
// https://stackoverflow.com/questions/7176908/how-to-get-index-of-object-by-its-property-in-javascript
const findWithAttr = (array, attr, value) => {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    // If the requested object is not found, return -1
    return -1;
}

// This function accepts an error object, unpack, print to console, and return the specific error message for user
const handleServerErrorMessage = (error) => {
    try {
        // if (JSON.stringify(error).toLowerCase().includes('network error')) {
        //     return '[Error] Network error';
        // };
        console.log('error', error);
        console.log('error.response', error.response);
        console.log('error.response.data', error.response.data);
        // console.log('error.response.status', error.response.status);
        // console.log('error.response.headers', error.response.headers);

        const customServerErrorMessage = error.response.data.message;
        // Check if the message is an object. If yes, use a default error message.
        // The custom error message from the backend is in a String format.
        // An object means that it is a system-generated error message. It will crash the frontend if not handled.
        if (typeof customServerErrorMessage === 'object' && customServerErrorMessage !== null) {
            return '[Error] Gagal melanjutkan'
        }
        return customServerErrorMessage;
    } catch(e) {
        return '[Error] Gagal melanjutkan'
    }
}

const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
});

const formatCurrency = (value, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
    }).format(value);
}

const formatDecimal = (value, minimumFractionDigits = 0, maximumFractionDigits = 6) => {
    // return new Intl.NumberFormat('id-ID', {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
    }).format(value);
}

const formatInteger = (value) => {
    // return new Intl.NumberFormat('id-ID', {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(value);
}

const formatPercentage = (value, minimumFractionDigits = 0, maximumFractionDigits = 2) => {
    // return new Intl.NumberFormat('id-ID', {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
    }).format(value);
}

// Format mobile phone number
const formatPhoneNumber = (value) => {
    let result = value;
    if (value[0] === '0') {
        // Eg. Convert 08137778822 to +628137778822
        result = value.replace('0', '+62');
    } else if (value[0] === '6' && value[1] === '2') {
        // Eg. Convert 628137778822 to +628137778822
        result = '+' + value;
    }
    return result;
}

const getAuthorizationHeader = () => {
    const token = getLoggedInUser().token;
    return { 
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
}

// const silentlyLoginWithInterval = () => {
//     //Set timer every 1 hour to silently login to refresh the token (Firebase workaround)
//     setTimeout(() => {
//         const data = {
//             email: 'gsd1@gmail.com',
//             password: 'Gondrong5'
//         }
//         axios.post('/login', data)
//             .then((response) => {
//                 const user = {...response.data};
//                 let cookies = new Cookies();
//                 cookies.set('user', JSON.stringify(user), { path: '/' });
//             })
//             .catch((error) => {
//                 console.log(error);
//             })
//     }, 4000);
// }

const getMonthFromIndex = (index) => {
    const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    return months[index];
}

export { findWithAttr, handleServerErrorMessage, createOption, formatCurrency, formatDecimal, formatInteger, formatPercentage,
    formatPhoneNumber, getAuthorizationHeader, getMonthFromIndex };
